<template>
  <register-user-vue />
</template>
<script>
import RegisterUserVue from "./components/RegisterUser.vue";
export default {
  name: "RegistrasiPerusahaan",
  components: {
    RegisterUserVue,
  },
  data: () => ({}),
};
</script>
    
    <style>
</style>