<template>
  <registrasi-component-vue />
</template>
  
  <script>
import LoginComponentVue from "./components/LoginComponent.vue";
import RegistrasiComponentVue from "./components/RegistrasiComponent.vue";
export default {
  name: "RegistrasiPerusahaan",
  components: {
    RegistrasiComponentVue,
  },
  data: () => ({}),
};
</script>
  
  <style>
</style>