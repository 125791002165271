<template>
  <login-mode1-component />
</template>

<script>
import LoginComponentVue from "./components/LoginComponent.vue";
import LoginMode1Component from "./components/LoginMode1Component.vue";
export default {
  name: "Login",
  components: {
    LoginMode1Component,
  },
  data: () => ({}),
};
</script>

<style>
</style>