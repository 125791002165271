<template>
  <div :class="device.desktop ? `home pa-6`:`home pa-0`">
    <v-row>
      <v-col :cols="device.desktop ? `12` : `12`">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="theme.color + ` lighten-1`">
            <v-col cols=12>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="table.options.tgl1"
                    placeholder="Dari Tangal"
                    single-line
                    hide-details
                    dense
                    solo
                    type="date"
                    :color="theme.color"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="table.options.tgl2"
                    placeholder="Sampai Tanggal"
                    single-line
                    hide-details
                    dense
                    solo
                    type="date"
                    :color="theme.color"
                  ></v-text-field>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    placeholder="Dari Kode Barang"
                    outlined
                    dense
                    hide-details
                    v-model="table.options.kodebarang1"
                    :items="bahanbakus"
                    background-color="white"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    placeholder="Sampai Kode Barang"
                    outlined
                    dense
                    hide-details
                    v-model="table.options.kodebarang2"
                    :items="bahanbakus"
                    background-color="white"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="table.options.search"
                    append-icon="mdi-magnify"
                    label="Pencarian"
                    single-line
                    hide-details
                    dense
                    solo
                    :color="theme.color"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-btn
                    class="orange white--text mr-2"
                    @click="fetchRecords"
                  >Refresh</v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="orange"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Export
                        <v-icon
                          small
                          class="ml-2"
                        >mdi-arrow-down-drop-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="postExportXls()">
                        <v-list-item-title><v-icon
                            color="green"
                            class="mr-2"
                          >mdi-file-excel</v-icon> Excel</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title><v-icon
                            color="red"
                            class="mr-2"
                          >mdi-file-pdf</v-icon> PDF</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-icon
                    small
                    color="green"
                    class="mr-2"
                  >mdi-circle</v-icon><span class="mr-2 white--text subtitle-1">Saldo Awal</span>
                  <v-icon
                    small
                    color="blue"
                    class="mr-2"
                  >mdi-circle</v-icon><span class="mr-2 white--text subtitle-1">Pembelian Bahan</span>
                  <v-icon
                    small
                    color="red"
                    class="mr-2"
                  >mdi-circle</v-icon><span class="mr-2 white--text subtitle-1">Pemakaian Bahan</span>
                  <v-icon
                    small
                    color="purple"
                    class="mr-2"
                  >mdi-circle</v-icon><span class="mr-2 white--text subtitle-1">Saldo Akhir</span>
                </v-col>

              </v-row>
            </v-col>
          </v-card-title>
          <v-data-table
            v-if="device.desktop"
            :headers="headers"
            :items="records"
            :options.sync="table.options"
            :server-items-length="table.total"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.onhand="{ value }">
              <span class="green--text ">{{ value }}</span>
            </template>
            <template v-slot:item.totalawal="{ value }">
              <span class="green--text ">{{ value }}</span>
            </template>
            <template v-slot:item.qtyin="{ value }">
              <span class="blue--text ">{{ value }}</span>
            </template>
            <template v-slot:item.totalin="{ value }">
              <span class="blue--text ">{{ value }}</span>
            </template>
            <template v-slot:item.qtyout="{ value }">
              <span class="red--text ">{{ value }}</span>
            </template>
            <template v-slot:item.totalout="{ value }">
              <span class="red--text ">{{ value }}</span>
            </template>
            <template v-slot:item.saldoakhir="{ value }">
              <span class="purple--text font-weight-bold ">{{ value }}</span>
            </template>
            <template v-slot:item.totalakhir="{ value }">
              <span class="purple--text font-weight-bold ">{{ value }}</span>
            </template>
            <template v-slot:item.ro="{ value }">
              <span class="red--text font-weight-bold ">{{ value }}</span>
            </template>
            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item
                    @click="editRecord(value)"
                    v-show="page.actions.edit"
                  >
                    <v-list-item-title>
                      <v-icon color="orange">mdi-pencil-circle</v-icon>
                      Ubah Data
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="page.actions.delete"
                    @click="postDeleteRecord(value)"
                  >
                    <v-list-item-title>
                      <v-icon color="red">mdi-delete-circle</v-icon>
                      Hapus Data
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </template>
          </v-data-table>
          <v-list
            subheader
            v-show="device.mobile"
          >

            <v-list-item-group
              multiple
              active-class=""
            >
              <v-list-item
                v-for="(item,index) in filterItems"
                :key="index"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.kode }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.nama }} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="
                                    center
                                    center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>
                        <v-list-item @click="editRecord(item.id)">
                          <v-list-item-title>
                            <v-icon color="orange">mdi-pencil-circle</v-icon>
                            Ubah Data
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="postDeleteRecord(item.id)">
                          <v-list-item-title>
                            <v-icon color="red">mdi-delete-circle</v-icon>
                            Hapus Data
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="form.add"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
        :fullscreen="device.mobile"
      >,
        <v-card class="rounded-0">
          <v-toolbar
            :color="theme.color + ` lighten-1`"
            dark
          >
            <div class="box-icon-dialog">
              <v-icon
                small
                color="orange"
                class="mr-1 animate__animated animate__flash infinite"
              >mdi-circle</v-icon>
            </div>
            Formulir Fix Ledger
          </v-toolbar>
          <div>
            <v-card-text
              style=""
              :class="device.desktop ? `box-dialog-content-desktop`:``"
            >
              <v-col cols="12">
                <v-text-field
                  label="Kode Area"
                  :color="theme.color"
                  hide-details
                  autocomplete="off"
                  v-model="record.kode"
                  outlined
                  dense
                  :filled="record.kode"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Nama Area"
                  :color="theme.color"
                  hide-details
                  autocomplete="off"
                  v-model="record.nama"
                  outlined
                  dense
                  :filled="record.nama"
                >
                </v-text-field>
              </v-col>

            </v-card-text>
          </div>
          <v-card-actions class="flex flex-row-reverse">
            <v-btn
              outlined
              :color="theme.color"
              @click="postAddNewRecord"
              v-show="!form.edit"
            >TAMBAH</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              @click="postUpdateRecord"
              v-show="form.edit"
            >UBAH</v-btn>
            <v-btn
              outlined
              class="mr-2"
              color="grey dark-3"
              @click="closeForm"
            >BATAL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>
              
<script>
import { mapActions, mapState } from "vuex";
import XLSX from "xlsx";

export default {
  name: "master-data-jenis-permohonan",
  data: () => ({
    num: 1,
    headers: [
      {
        text: "KODE",
        align: "start",
        sortable: false,
        value: "kodebarang",
        width: 100,
      },
      {
        text: "NAMA BARANG",
        align: "start",
        sortable: false,
        value: "namabarang",
        width: 300,
      },
      {
        text: "SATUAN",
        align: "start",
        sortable: false,
        value: "namasatuan",
        width: 100,
      },
      {
        text: "Qty.awal",
        align: "right",
        sortable: false,
        value: "onhand",
        width: 110,
        class: "green--text",
      },
      {
        text: "H.Satuan",
        align: "right",
        sortable: false,
        value: "hargasatuan",
        width: 110,
        class: "green--text",
      },
      {
        text: "Total",
        align: "right",
        sortable: false,
        value: "totalawal",
        width: 110,
        class: "green--text",
      },
      {
        text: "Qty.In",
        align: "right",
        sortable: false,
        value: "qtyin",
        width: 110,
        class: "blue--text",
      },
      {
        text: "H.Satuan",
        align: "right",
        sortable: false,
        value: "hargasatuan",
        width: 110,
        class: "blue--text",
      },
      {
        text: "Total",
        align: "right",
        sortable: false,
        value: "totalin",
        width: 110,
        class: "blue--text",
      },
      {
        text: "Qty.out",
        align: "right",
        sortable: false,
        value: "qtyout",
        width: 110,
        class: "red--text",
      },
      {
        text: "H.Satuan",
        align: "right",
        sortable: false,
        value: "hargasatuan",
        width: 110,
        class: "red--text",
      },
      {
        text: "Total",
        align: "right",
        sortable: false,
        value: "totalout",
        width: 110,
        class: "red--text",
      },
      {
        text: "Qty.Akhir",
        align: "right",
        sortable: false,
        value: "saldoakhir",
        width: 110,
        class: "purple--text",
      },
      {
        text: "H.Satuan",
        align: "right",
        sortable: false,
        value: "hargasatuan",
        width: 110,
        class: "purple--text",
      },
      {
        text: "Total",
        align: "right",
        sortable: false,
        value: "totalakhir",
        width: 110,
        class: "purple--text",
      },
    ],

    search: null,
    tahun: null,
    bulan: null,
    kode: null,
    foto: "/",
    bahanbakus: [],

    dataexports: [],

    kodes: [
      { text: "Jurnal Beli", value: "JB" },
      { text: "Jurnal Penjualan", value: "JP" },
      { text: "Jurnal Umum", value: "JU" },
    ],
  }),
  computed: {
    ...mapState([
      "page",
      "form",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "table",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/pembelian/laporan/kartu-stok-bahan-baku",
      pagination: true,
      title: "KARTU STOK",
      subtitle: "Berikut Daftar Seluruh Kartu Stok Bahan Baku Yang Tersedia",
      edit: false,
      breadcrumbs: [
        {
          text: "PEMBELIAN",
          disabled: true,
          href: "",
        },
        {
          text: "Laporan",
          disabled: true,
          href: "",
        },
        {
          text: "Kartu Stok",
          disabled: true,
          href: "",
        },
      ],
      actions: {
        refresh: true,
        add: false,
        edit: true,
        delete: true,
        bulkdelete: false,
        export: false,
        print: false,
        help: false,
      },
    });
  },
  mounted() {
    this.fetchBahanBakus();
  },
  methods: {
    ...mapActions([
      "setPage",
      "setForm",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
    ]),
    openForm: function () {
      this.setForm({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setForm({
        add: false,
        edit: false,
      });
    },

    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        //this.fetchRecords();
        this.closeForm();
      });
    },
    editRecord: function (val) {
      this.postEdit(val).then(() => {});
      this.setForm({
        add: true,
        edit: true,
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        //this.fetchRecords();
        this.closeForm();
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    uploadFoto: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "gallery",
        },
        callback: (response) => {
          setTimeout(() => {
            this.foto = response.path;
            this.record.filename = response.name;
          }, 1000);
        },
      });
    },

    fetchBahanBakus: async function () {
      try {
        let { data } = await this.http.get("api/combo/bahan-baku");
        this.bahanbakus = data;
      } catch (error) {}
    },
    postExportXls: async function () {
      try {
        let { data } = await this.http.get(
          "api/pembelian/laporan/kartu-stok-bahan-baku-export-xls?kodebarang1=" +
            this.table.options.kodebarang1 +
            "&kodebarang2=" +
            this.table.options.kodebarang2 +
            "&tgl1=" +
            this.table.options.tgl1 +
            "&tgl2=" +
            this.table.options.tgl2
        );
        var dataxls = XLSX.utils.json_to_sheet(data);

        // A workbook is the name given to an Excel file
        var wb = XLSX.utils.book_new(); // make Workbook of Excel

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, dataxls, "Data Stok"); // sheetAName is name of Worksheet
        //XLSX.utils.book_append_sheet(wb, pokemonWS, "pokemons");

        // export Excel file
        XLSX.writeFile(wb, "kartu-stok-bahan-baku.xlsx");
      } catch (error) {}
    },
  },
  watch: {
    "table.options": {
      handler() {
        this.fetchRecords();
      },
      deep: true,
    },
  },
};
</script>
      <style>
.box-dialog-content-desktop {
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: scroll;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
              