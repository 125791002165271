<script>
import { Bar } from "vue-chartjs";
import { mapActions, mapState } from "vuex";

export default {
  name: "penjualan-per-bulan",
  extends: Bar,

  data: () => ({
    color: "#3F51B5",
  }),

  computed: {
    ...mapState(["http", "theme"]),
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: async function () {
      let { data } = await this.http.get("api/omsetpertahun");
      this.renderChart(
        {
          labels: [
            "Januari",
            "Pebruari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "July",
            "Augustus",
            "September",
            "Oktober",
            "Nopember",
            "Desember",
          ],
          options: this.options,
          datasets: [
            {
              label: "Statistik Penjulan Per Bulan Tahun 2023",
              backgroundColor: "#3F51B5",
              data: data,
            },
          ],
        },

        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (label, index, labels) {
                    return label + " M";
                  },
                  beginAtZero: true,
                },
                scaleLabel: {
                  display: false,
                  labelString: "",
                },
              },
            ],
          },
        }
      );
    },
  },
};
</script>
