<template>
  <div :class="device.desktop ?`home pa-6`:`home pa-0`">

    <v-row>
      <v-col col="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse ` + theme.color + ` lighten-1`">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="form.new = true"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              dense
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.progress="{ value }">
              <v-progress-linear
                :color="theme.color"
                v-model="value"
                height="25"
              >
                <strong>{{ value }}%</strong>
              </v-progress-linear>
            </template>
            <template v-slot:item.status="{ value }">
              <v-chip
                :color="value.color"
                small
              >{{ value.text }}</v-chip>
            </template>
            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item @click="openProgress(value)">
                    <v-list-item-title>
                      <v-icon color="grey">mdi-progress-check</v-icon>
                      Set Progress
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postSelesai(value)">
                    <v-list-item-title>
                      <v-icon color="purple">mdi-water-check</v-icon>
                      Set Selesai
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="editRecord(value)">
                    <v-list-item-title>
                      <v-icon color="orange">mdi-pencil-circle</v-icon>
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postDeleteRecord(value)">
                    <v-list-item-title>
                      <v-icon color="red">mdi-delete-circle</v-icon>
                      Hapus
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="form.new"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            :dark="theme.mode"
          >
            <v-icon class="mr-1">mdi-circle</v-icon> Formulir Permohonan Fitur
          </v-toolbar>
          <v-card-text>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Fitur"
                v-model="record.title"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                label="Perihal / Permasalahan"
                :color="theme.color"
                dense
                outlined
                v-model="record.body"
                hide-details
              >
                {{ record.perihal }}
              </v-textarea>
            </v-col>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show="!form.edit"
              @click="postAddNewRecord"
            >Kirim</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="form.edit"
              @click="postUpdateRecord"
            >Kirim</v-btn>
            <v-btn
              outlined
              color="grey"
              @click="closeNewForm"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="progress.form.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            :dark="theme.mode"
          >
            <v-icon class="mr-1">mdi-circle</v-icon> Formulir Progres Pekerjaan
          </v-toolbar>
          <v-card-text>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Progres (%)"
                v-model="record.progress"
                dense
                type="number"
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show="!form.edit"
              @click="postSetProgress"
            >Kirim</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="form.edit"
              @click="postSetProgress"
            >Kirim</v-btn>
            <v-btn
              outlined
              color="grey"
              @click="progress.form.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script lang="vue">
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "PEMOHON", value: "pemohon", align:'left', sortable:false },
      {
        text: "FITUR",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "TGL. PENGAJUAN", value: "date_request", width:160, align:'center' },
      { text: "TGL. PROSES", value: "date_progress", align:'center',width:160, sortable:false },
      { text: "PROG(%)", value: "progress", align:'center', sortable:false, width:100 },
      { text: "STATUS", value: "status", align:'center', sortable:false, width:100 },
      { text: "AKSI", value: "id", width:100, sortable:false, align:'center' },
    ],

    form: {
      new: false,
      edit: false,
    },

    search: null,
    path: null,

    progress:{
        form:{
            show:false
        }
    }
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar"
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/utility/fiturs",
      pagination:false,
      title: "MANAJEMEN FITUR",
      subtitle: "Berikut Daftar Seluruh Permohonan Fitur",
      breadcrumbs: [
        {
          text: "Manajemen Fitur",
          disabled: false,
          href: "",
        },
        {
          text: "Perhomonan",
          disabled: false,
          href: "utility-manajemen-fitur",
        },
      ],
    }).then(()=> {
      this.fetchRecords();
    });
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading"
    ]),
    openNewForm: function () {
      this.form.new = true;
      this.form.edit = false;
    },
    closeNewForm: function () {
      this.form.new = false;
      this.form.edit = false;
    },
    openEditForm: function () {
      this.form.new = true;
      this.form.edit = true;
    },
    openJadwal: function (val){
      this.$router.push({name:'master-event-jadwal', params:{event_id:val}})
    },
    openDokumen: function(val){
      this.$router.push({name:'master-event-dokumen',params
      :{event_id:val}})
    },
    postAddNewRecord: function (){
      this.postAddNew(this.record).then(()=>{
        this.fetchRecords()
        this.form.new= false
      })
    },
    editRecord: function(val) {
      this.postEdit(val);
      this.form.new = true;
      this.form.edit = true;
    },
     postUpdateRecord: function() {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.form.new = false;
        this.form.edit = false;
      });
    },

    postDeleteRecord: function(val) {
      this.postConfirmDelete(val);
    },
    postDownload(val) {
      window.open(val, "__blank");
    },

    openProgress: function (value) {
        this.record.id = value
        this.progress.form.show = true
    },

    postSetProgress: async function () {
        try {
            this.setLoading({dialog: true, text:"Update Progress"})
            let {data:{status, message}}= await this.http.post("api/utility/fiturs-set-progress", this.record);
            if(!status){
                this.snackbar.color ="orange"
                this.snackbar.text = message
                this.snackbar.state = true
                return
            }
            this.snackbar.color = this.theme.color
            this.snackbar.text = message
            this.snackbar.state = true
            this.progress.form.show = false
            this.fetchRecords()
        } catch (error) {
            this.snackbar.color = "red"
            this.snackbar.text = "Opps..., terjadi kesalahan "+ error
            this.snackbar.state = true
        }finally{
            this.setLoading({dialog:false, text:""})
        }  
    },

    postSelesai: async function (value) {
        try {
            this.setLoading({dialog:true, text:"Set Selesai"})
            let{data:{status,message}}= await this.http.post("api/utility/fiturs-set-selesai", {id:value})
            if(!status){
                this.snackbar.color = "orange"
                this.snacknar.text = message
                this.snackbar.state= true
                return
            }

            this.snackbar.color = this.theme.color
            this.snackbar.text = message
            this.snackbar.state = true
            this.fetchRecords()
        } catch (error) {
            this.snackbar.color = "red"
            this.snackbar.text = "Opps..., terjadi kesalahan "+ error
            this.snackbar.state = true 
        }finally{
            this.setLoading({dialog:false, text:""})
        }
    }

  },

};
</script>