import Vue from "vue";
//page FronteEnd
import VueRouter from "vue-router";
import BaseFrontend from "../views/frontend/Base.vue";
import Auth from "../providers/AuthProviders";
import Login from "../views/frontend/Login.vue";
import RegistrasiPerusahaan from "../views/frontend/RegistrasiPerusahaan.vue";
import RegisterUser from "../views/frontend/RegisterUser.vue";
import RegistrasiBerhasil from "../views/frontend/RegistrasiBerhasil.vue";
//import Register from "../views/auth/Register.vue";
import Home from "../views/frontend/Home.vue";

//BASE APLIKASI
import Base from "../views/backend/Base.vue";

//Dashboard Page
import Dashboard from "../views/backend/pages/Dashboard.vue";
import PageNotFound from "../views/backend/pages/exception/PageNotFound.vue";

//Utiity Group Page
import User from "../views/backend/pages/utility/user/Index.vue";
import Changepwd from "../views/backend/pages/utility/user/Changepwd.vue";
import ProfilAkun from "../views/backend/pages/utility/user/Profil.vue";
import UtilityManajemenFitur from "../views/backend/pages/utility/manajemenfitur/Index.vue";
import UtilitymanajemenFiturAdministrator from "../views/backend/pages/utility/manajemenfitur/administrator/Index.vue";
import UtilityUpdateHistory from "../views/backend/pages/utility/updatehistory/Index.vue";
import UtilityUpdateHistoryItem from "../views/backend/pages/utility/updatehistory/items/Index.vue";

//page master data
import MasterAppInfo from "../views/backend/pages/masterdata/appinfo/Index.vue";
import MasterDepartement from "../views/backend/pages/masterdata/departement/Index.vue";
import MasterJenisPermohonan from "../views/backend/pages/masterdata/jenispermohonan/Index.vue";

//page Pembelian
import PembelianLaporanPosisiStok from "../views/backend/pages/pembelian/laporan/posisistokbahanbaku/Index.vue";
import PembelianLaporanKartuStok from "../views/backend/pages/pembelian/laporan/kartustokbahanbaku/Index.vue";

//modul penjualan
import PenjualanMasterDataArea from "../views/backend/pages/penjualan/masterdata/area/Index.vue";
import PenjulanMasterDataCustomer from "../views/backend/pages/penjualan/masterdata/customers/Index.vue";

import PenjualanGudangSalesOrder from "../views/backend/pages/penjualan/gudang/salesorder/index.vue";

/**
 * KEUANGAN
 */
//Table
import KeuanganTableBank from "../views/backend/pages/keuangan/table/bank/Index.vue";

//Utility
import KeuanganUtilityFixLedgerPpn from "../views/backend/pages/keuangan/utility/fixledgerppn/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "",
    component: BaseFrontend,
    children: [
      { path: "", redirect: { name: "login" } },
      { path: "home", name: "home", component: Home },
      {
        path: "registrasi-perusahaan",
        name: "registrasi-perusahaan",
        component: RegistrasiPerusahaan,
      },
      {
        path: "registrasi-user",
        name: "registrasi-user",
        component: RegisterUser,
      },
      {
        path: "registrasi-berhasil",
        name: "registrasi-berhasil",
        component: RegistrasiBerhasil,
      },
      { path: "login", name: "login", component: Login },
    ],
  },
  /**
   * Route Backend
   */

  {
    path: "/auth",
    name: "",
    meta: {
      auth: true,
    },
    component: Base,
    children: [
      { path: "", redirect: { name: "dashboard" } },
      { path: "dashboard", name: "dashboard", component: Dashboard },
      { path: "user", name: "user", component: User },
      {
        path: "chngpwd",
        name: "chngpwd",
        component: Changepwd,
      },
      {
        path: "profil-akun",
        name: "profil-akun",
        component: ProfilAkun,
      },
      //route master data
      {
        path: "master-app-info",
        name: "master-app-info",
        component: MasterAppInfo,
      },
      {
        path: "master-departement",
        name: "master-departement",
        component: MasterDepartement,
      },
      {
        path: "master-jenis-permohonan",
        name: "master-jenis-permohonan",
        component: MasterJenisPermohonan,
      },

      /**
       * Route Modul Pembelian
       */
      {
        path: "pembelian-laporan-posisistok",
        name: "pembelian-laporan-posisistok",
        component: PembelianLaporanPosisiStok,
      },
      {
        path: "pembelian-laporan-kartustok",
        name: "pembelian-laporan-kartustok",
        component: PembelianLaporanKartuStok,
      },

      /**
       * Route Modul Penjualan
       */
      {
        path: "penjualan-master-data-area",
        name: "penjualan-master-data-area",
        component: PenjualanMasterDataArea,
      },
      {
        path: "penjualan-master-data-customer",
        name: "penjualan-master-data-customer",
        component: PenjulanMasterDataCustomer,
      },

      {
        path: "penjualan-gudang-sales-order",
        name: "penjualan-gudang-sales-order",
        component: PenjualanGudangSalesOrder,
      },

      /**
       * Route Keuangan
       */
      {
        path: "keuangan-table-bank",
        name: "keuangan-table-bank",
        component: KeuanganTableBank,
      },
      {
        path: "keuangan-utility-fix-ldeger-ppn",
        name: "keuangan-utility-fix-ledger-ppn",
        component: KeuanganUtilityFixLedgerPpn,
      },

      /**
       * Route Utility
       */
      {
        path: "utility-manajemen-fitur-administrator",
        name: "utility-manajemen-fitur-administrator",
        component: UtilitymanajemenFiturAdministrator,
      },
      {
        path: "utility-manajemen-fitur",
        name: "utility-manajemen-fitur",
        component: UtilityManajemenFitur,
      },
      {
        path: "utility-update-history",
        name: "utility-update-history",
        component: UtilityUpdateHistory,
      },
      {
        path: "utility-update-history-items/:update_history_id",
        name: "utility-update-history-items",
        component: UtilityUpdateHistoryItem,
      },
      {
        path: "*",
        name: "page-not-found",
        component: PageNotFound,
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

/**
 * router match
 */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);

  if (requiresAuth) {
    if (!Auth.check) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && Auth.check) {
      if (Auth.user.authent == "peserta") {
        next({ name: "peserta-dashboard" });
      } else if (Auth.user.auth === "io") {
        next({ name: "dashboard" });
      } else {
        next({ name: "dashboard" });
      }
    } else {
      next();
    }
  }
});

export default router;
