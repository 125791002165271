<template>
  <div :class="device.desktop ? `home pa-6`:`home pa-0`">
    <v-row>
      <v-col :cols="device.desktop ? `12` : `12`">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="theme.color + ` lighten-1`">
            <v-col cols=12>
              <v-row>
                <v-col cols="3">
                  <v-select
                    placeholder="Kode Transaksi"
                    class="white--text"
                    outlined
                    dense
                    v-model="table.options.kode"
                    :items="kodes"
                    hide-details
                    background-color="white"
                  >
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="table.options.fromdate"
                    placeholder="Dari Tangal"
                    single-line
                    hide-details
                    dense
                    solo
                    type="date"
                    :color="theme.color"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="table.options.todate"
                    placeholder="Sampai Tanggal"
                    single-line
                    hide-details
                    dense
                    solo
                    type="date"
                    :color="theme.color"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-select
                    placeholder="Kolom Pencarian"
                    class="white--text"
                    outlined
                    dense
                    v-model="table.options.col"
                    :items="koloms"
                    background-color="white"
                    hide-details
                  >
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="table.options.search"
                    append-icon="mdi-magnify"
                    label="Pencarian"
                    single-line
                    hide-details
                    dense
                    solo
                    :color="theme.color"
                    class="mb-5"
                  ></v-text-field>
                </v-col>
                <v-col col="3">
                  <v-btn
                    color="orange"
                    class="white--text mr-2"
                    @click="fetchRecords"
                  >Refresh</v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="orange"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Aksi
                        <v-icon
                          small
                          class="ml-2"
                        >mdi-arrow-down-drop-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="postFixBulkPpn">
                        <v-list-item-title>Perbaikan PPN</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Justify</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Un Posting</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Hapus Data</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-card-title>
          <v-data-table
            v-if="device.desktop"
            :headers="headers"
            :items="records"
            :options.sync="table.options"
            :server-items-length="table.total"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.notrx="{ value }">
              <v-badge
                v-show="value.badge"
                color="red"
                content="P"
                small
              >{{ value.text }}</v-badge>
              <span v-show="!value.badge">{{ value.text }}</span>
            </template>
            <template v-slot:item.debet="{ value }">
              <span class="blue--text font-weight-bold">{{ value }}</span>
            </template>
            <template v-slot:item.kredit="{ value }">
              <span class="red--text font-weight-bold">{{ value }}</span>
            </template>
            <template v-slot:item.aksi="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item @click="postFixPpn(value)">
                    <v-list-item-title>
                      <v-icon :color="theme.color">mdi-checkbox-marked-circle</v-icon>
                      Fix PPN 11 %
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="editRecord(value.id)"
                    v-show="page.actions.edit"
                  >
                    <v-list-item-title>
                      <v-icon color="orange">mdi-pencil-circle</v-icon>
                      Ubah Data
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="page.actions.delete"
                    @click="postDeleteRecord(value.id)"
                  >
                    <v-list-item-title>
                      <v-icon color="red">mdi-delete-circle</v-icon>
                      Hapus Data
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </template>
          </v-data-table>
          <v-list
            subheader
            v-show="device.mobile"
          >

            <v-list-item-group
              multiple
              active-class=""
            >
              <v-list-item
                v-for="(item,index) in filterItems"
                :key="index"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.kode }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.nama }} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="
                                center
                                center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>

                        <v-list-item @click="editRecord(item.id)">
                          <v-list-item-title>
                            <v-icon color="orange">mdi-pencil-circle</v-icon>
                            Ubah Data
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="postDeleteRecord(item.id)">
                          <v-list-item-title>
                            <v-icon color="red">mdi-delete-circle</v-icon>
                            Hapus Data
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="form.add"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
        :fullscreen="device.mobile"
      >,
        <v-card class="rounded-0">
          <v-toolbar
            :color="theme.color + ` lighten-1`"
            dark
          >
            <div class="box-icon-dialog">
              <v-icon
                small
                color="orange"
                class="mr-1 animate__animated animate__flash infinite"
              >mdi-circle</v-icon>
            </div>
            Formulir Fix Ledger
          </v-toolbar>
          <div>
            <v-card-text
              style=""
              :class="device.desktop ? `box-dialog-content-desktop`:``"
            >
              <v-col cols="12">
                <v-text-field
                  label="Kode Area"
                  :color="theme.color"
                  hide-details
                  autocomplete="off"
                  v-model="record.kode"
                  outlined
                  dense
                  :filled="record.kode"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Nama Area"
                  :color="theme.color"
                  hide-details
                  autocomplete="off"
                  v-model="record.nama"
                  outlined
                  dense
                  :filled="record.nama"
                >
                </v-text-field>
              </v-col>

            </v-card-text>
          </div>
          <v-card-actions class="flex flex-row-reverse">
            <v-btn
              outlined
              :color="theme.color"
              @click="postAddNewRecord"
              v-show="!form.edit"
            >TAMBAH</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              @click="postUpdateRecord"
              v-show="form.edit"
            >UBAH</v-btn>
            <v-btn
              outlined
              class="mr-2"
              color="grey dark-3"
              @click="closeForm"
            >BATAL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>
          
          <script>
import { mapActions, mapState } from "vuex";

export default {
  name: "master-data-jenis-permohonan",
  data: () => ({
    num: 1,

    headers: [
      {
        text: "TGL. TRX",
        align: "start",
        sortable: false,
        value: "tgltrx",
        width: 100,
      },
      {
        text: "KODE TRX",
        align: "center",
        sortable: false,
        value: "kdtrx",
        width: 110,
      },
      {
        text: "NO. TRX",
        align: "center",
        sortable: "false",
        value: "nomortrx",
        width: 120,
      },
      {
        text: "NO. ACTRX",
        align: "center",
        sortable: false,
        value: "noactrx",
        width: 120,
      },
      {
        text: "DESC",
        align: "start",
        sortable: true,
        value: "desctrx",
      },
      {
        text: "DEBET",
        align: "right",
        sortable: true,
        value: "debet",
        class: "blue--text",
      },
      {
        text: "KREDIT",
        align: "right",
        sortable: true,
        value: "kredit",
        class: "red--text",
      },
      {
        text: "SALDO",
        align: "right",
        sortable: true,
        value: "saldo",
        class: "green--text",
      },
      {
        text: "AKSI",
        value: "aksi",
        sortable: false,
        width: 75,
        align: "center",
      },
    ],

    search: null,
    tahun: null,
    bulan: null,
    kode: null,
    foto: "/",

    kodes: [
      { text: "Jurnal Beli", value: "JB" },
      { text: "Jurnal Penjualan", value: "JP" },
      { text: "Jurnal Umum", value: "JU" },
    ],
    koloms: [
      { text: "No Transaksi", value: "notrx" },
      { text: "No Akun Transaksi", value: "noactrx" },
      { text: "Deskripsi Transaksi", value: "desctrx" },
    ],
  }),
  computed: {
    ...mapState([
      "page",
      "form",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "table",
      "snackbar",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/keuangan/utility/fix-ledger-ppn",
      pagination: true,
      title: "PERBAIKAN LEDGER",
      subtitle: "Berikut Daftar Seluruh Daftar Ledger Yang Tersedia",
      edit: false,
      breadcrumbs: [
        {
          text: "KEUANGAN",
          disabled: true,
          href: "",
        },
        {
          text: "Utilitas",
          disabled: true,
          href: "",
        },
        {
          text: "Perbaikan Ledger",
          disabled: true,
          href: "",
        },
      ],
      actions: {
        refresh: true,
        add: false,
        edit: true,
        delete: true,
        bulkdelete: false,
        export: false,
        print: false,
        help: false,
      },
    });
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "setForm",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
    ]),
    openForm: function () {
      this.setForm({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setForm({
        add: false,
        edit: false,
      });
    },

    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        //this.fetchRecords();
        this.closeForm();
      });
    },
    editRecord: function (val) {
      this.postEdit(val).then(() => {});
      this.setForm({
        add: true,
        edit: true,
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        //this.fetchRecords();
        this.closeForm();
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    uploadFoto: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "gallery",
        },
        callback: (response) => {
          setTimeout(() => {
            this.foto = response.path;
            this.record.filename = response.name;
          }, 1000);
        },
      });
    },
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log("o/ we are watching!!!");
    },
    postFixPpn: async function (val) {
      try {
        let {
          data: { code, success, message, error },
        } = await this.http.post("api/keuangan/utility/fix-ledger-ppn-trx", {
          id: val.id,
          kdtrx: val.kdtrx,
        });

        if (!success) {
          (this.snackbar.color = "orange"), (this.snackbar.text = message);
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchRecords();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan";
        this.snackbar.state = true;
      }
    },

    postFixBulkPpn: async function () {
      try {
        let {
          data: { code, success, message, error },
        } = await this.http.post(
          "api/keuangan/utility/fix-ledger-bulk-ppn-trx",
          {
            kdtrx: this.table.options.kode,
            fromdate: this.table.options.fromdate,
            todate: this.table.options.todate,
          }
        );

        if (!success) {
          (this.snackbar.color = "orange"), (this.snackbar.text = message);
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan";
        this.snackbar.state = true;
      }
    },
  },
  watch: {
    "table.options": {
      handler() {
        this.fetchRecords();
      },
      deep: true,
    },
  },
};
</script>
  <style>
.box-dialog-content-desktop {
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: scroll;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
          