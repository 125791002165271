<template>
  <registrasi-berhasil-component-vue />
</template>
  
  <script>
import RegistrasiBerhasilComponentVue from "./components/RegistrasiBerhasilComponent.vue";
export default {
  name: "registrasi-berhasil",
  components: {
    RegistrasiBerhasilComponentVue,
  },
  data: () => ({}),
};
</script>
  
  <style>
</style>