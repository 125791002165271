<template>
  <video-banner-vue />
</template>

<script>
import VideoBannerVue from "./components/videobaner/VideoBanner.vue";

export default {
  name: "home",
  components: {
    VideoBannerVue,
  },
  data: () => ({}),
};
</script>

<style>
</style>